import getConfig from 'next/config'

import { escapeForRegex } from '@nx/helpers'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const cornetteBaseUrl = publicRuntimeConfig.CORNETTE_BASE_URL
const cornetteSmartlingBaseUrl = publicRuntimeConfig.CORNETTE_SMARTLING_BASE_URL

const cornetteURL = new URL(cornetteBaseUrl)
const cornetteSmartlingURL = new URL(cornetteSmartlingBaseUrl)

//the paths within this array will be rendered with Cornette theme
const cornetteAllowedPaths = [
  '/about-us/',
  '/404/',
  '/logout/',
  '/services/',
  '/robots.txt',
]

const allowedPaths = new RegExp(
  `(^(?!(^\\/$|${cornetteAllowedPaths.map((path) => escapeForRegex(path)).join('|')})).*$)`
)

const redirects: Pick<RedirectConfig, 'source' | 'destination'>[] = [
  {
    source: allowedPaths,
    destination: `${baseUrl}{{0}}`,
  },
]

export const cornetteSubDomainRedirects: RedirectConfig[] = redirects.flatMap(
  (redirect) => [
    {
      hostname: cornetteURL.hostname,
      permanent: true,
      ...redirect,
    },
    {
      hostname: cornetteSmartlingURL.hostname,
      permanent: true,
      ...redirect,
    },
  ]
)
