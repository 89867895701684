import { EBrand } from '@bonhams1793/bonhams-typescript'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useDataLayer } from '@nx/utils/google-tag-manager'

interface DataLayerEntryType {
  brand?: string
}

const shouldPushNewBrand = (
  dataLayerHistory: (DataLayerEntryType | unknown)[],
  brand: EBrand
): boolean => {
  const reversedHistory = [...dataLayerHistory].reverse()
  for (const event of reversedHistory) {
    if (event instanceof Object && 'brand' in event) {
      return event.brand !== brand
    }
  }

  return true
}

export const PageViewMonitor = ({ brand }: { brand: EBrand }) => {
  const dataLayer = useDataLayer()
  const router = useRouter()

  useEffect(() => {
    if (shouldPushNewBrand(dataLayer.getHistory(), brand)) {
      dataLayer.push(() => ({
        brand,
      }))
    }

    dataLayer.push(() => ({
      event: 'pageview',
      page: {
        path: router.asPath,
        title: document.title,
      },
    }))
  }, [brand, dataLayer, router.asPath])

  return null
}
