import { Brand, EBrand } from '@bonhams1793/bonhams-typescript'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import Zendesk from 'react-zendesk'
import { ThemeProvider } from 'styled-components'

import { FireGlobalStyles } from '@nx/fire/global-styles'
import {
  FireNewsletterSignup,
  StyledSignupBannerTrigger,
} from '@nx/fire/newsletter-signup'
import { useIntersectionObserver } from '@nx/hooks/use-intersection-observer'
import { useUserIdTracking } from '@nx/utils/google-tag-manager'

import { ModalsDispatchContext } from '@web/components/common/ModalManager/ModalContext'
import { CurrencyConfig } from '@web/context/UserInfoProvider'
import { useGetUser } from '@web/helpers/api/user'
import { useCurrencyConversion } from '@web/helpers/hooks/useCurrencyConversion/useCurrencyConversion'
import { getTheme } from '@web/helpers/utils/getTheme/getTheme'
import { useUserFeatureFlagTracking } from '@web/helpers/utils/gtm/useUserFeatureFlagTracking'
import { GlobalProps } from '@web/types/app'

// DO NOT USE import from ../../common (it will cause a circular dependency)
import { TranslationsReturnHomeBanner } from '../../common/TranslationsReturnHomeBanner'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { MaintenanceStatus } from '../MaintenanceStatus'
import { ContentContainer } from './MainLayout.styles'

const { publicRuntimeConfig } = getConfig()

interface MainLayoutProps {
  children: React.ReactNode
  globalProps?: GlobalProps
  currencyConfig?: CurrencyConfig
  headerFooterBrand: EBrand
}

export const MainLayout = ({
  children,
  globalProps,
  headerFooterBrand,
  currencyConfig,
}: MainLayoutProps) => {
  const router = useRouter()
  useUserFeatureFlagTracking()
  useCurrencyConversion({ userIP: globalProps?.user.ip || '', currencyConfig })
  const dispatch = useContext(ModalsDispatchContext)

  const [
    footerRef,
    {
      isVisible: isVisibleFooter,
      entry: entryFooter,
      unobserve: unobserveFooter,
    },
  ] = useIntersectionObserver()
  const [
    triggerRef,
    { isVisible: isVisibleTrigger, unobserve: unobserveTrigger },
  ] = useIntersectionObserver()

  const { data: user } = useGetUser()

  useUserIdTracking(user?.customerId)

  const [clientSideActive, setClientSideActive] = useState(false)
  const [cookieBannerClosed, setCookieBannerClosed] = useState(
    !!globalProps?.user.userDismissCookieBox
  )
  useEffect(() => {
    const handleRouteChangeStart = () => {
      dispatch?.({ type: 'CLOSE', payload: undefined })

      if (!window.OneTrust?.IsAlertBoxClosed()) {
        window.OneTrust?.Close()
      }
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router.events, dispatch])

  useEffect(() => {
    return () => {
      unobserveTrigger()
      unobserveFooter()
    }
  }, [unobserveFooter, unobserveTrigger])

  useEffect(() => {
    const handleOneTrustChanged = () => {
      if (window.OneTrust?.IsAlertBoxClosed()) {
        setCookieBannerClosed(true)
      }
    }
    window.addEventListener('oneTrustChanged', handleOneTrustChanged)
    return () => {
      window.removeEventListener('oneTrustChanged', handleOneTrustChanged)
    }
  }, [])

  useEffect(() => {
    if (!clientSideActive) {
      setClientSideActive(true)
    }
  }, [clientSideActive])

  const theme = getTheme(headerFooterBrand)
  const brand = globalProps?.brand || Brand.bonhams

  return (
    <>
      <StyledSignupBannerTrigger
        ref={triggerRef}
        $height={(entryFooter?.target as HTMLElement)?.offsetTop || 0}
      />

      <FireGlobalStyles />

      <ThemeProvider theme={theme}>
        <Header brand={brand} headerBrand={headerFooterBrand} user={user} />
        <MaintenanceStatus />
      </ThemeProvider>

      <TranslationsReturnHomeBanner brand={brand} />

      <ContentContainer id="skip-to-content" tabIndex={-1}>
        {children}
      </ContentContainer>
      {user ? (
        <Zendesk
          defer
          zendeskKey="b821d6b7-8600-4341-b044-1a398da4ad63"
          webWidget={{
            zIndex: theme.zIndex.chatBubble,
          }}
        />
      ) : cookieBannerClosed ? (
        <FireNewsletterSignup
          apiUrl={publicRuntimeConfig.WEB_API_URL}
          webAssetsUrl={publicRuntimeConfig.WEB_ASSETS_BASE_URL}
          isVisibleTrigger={isVisibleTrigger}
          isVisibleFooter={isVisibleFooter}
        />
      ) : null}

      <Footer brand={headerFooterBrand} ref={footerRef} />

      {/* This div is used for Cypress tests to know when the client side rendering has finished */}
      {clientSideActive && <div data-cy="client-side-active"></div>}
    </>
  )
}
