import { Brand, EBrand } from '@bonhams1793/bonhams-typescript'

import { GTM_PLATFORM_ID, UserPersona } from './enums'

export interface UtilsGoogleTagManagerProps {
  googleTagManagerId: string
  userPersona?: UserPersona
  brand?: EBrand
  selectedCurrency?: string | null
}

export function UtilsGoogleTagManager({
  googleTagManagerId,
  userPersona = UserPersona.Guest,
  brand = Brand.bonhams,
  selectedCurrency = null,
}: UtilsGoogleTagManagerProps) {
  if (!googleTagManagerId) return null

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            platform_id: "${GTM_PLATFORM_ID}",
            user_persona: "${userPersona}",
            brand: "${brand}",
            selected_currency: "${selectedCurrency}"
          })`,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagManagerId}');`,
        }}
      />
    </>
  )
}

export function UtilsGoogleTagManagerNoScript({
  googleTagManagerId,
}: UtilsGoogleTagManagerProps) {
  if (!googleTagManagerId) return null

  return (
    <noscript>
      <iframe
        title="Google Tag Manager"
        src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
        height="0"
        width="0"
        css={`
          display: none;
          visibility: hidden;
        `}
      />
    </noscript>
  )
}
