import { Brand, EBrand } from '@bonhams1793/bonhams-typescript'

const BRAND_WHITELIST: EBrand[] = [
  Brand.bonhams,
  Brand.skinner,
  Brand.cornette,
  Brand.cars,
]

export function getSiteBrand(domain?: string, featureFlag?: EBrand): EBrand {
  if (featureFlag && BRAND_WHITELIST.includes(featureFlag)) {
    return featureFlag
  }

  if (!domain) return Brand.bonhams

  if (
    domain.includes('skinner.bonhams.com') ||
    domain.includes('preprod-skinner01.bonhams.com')
  ) {
    return Brand.skinner
  }

  if (
    domain.includes('csc-en.bonhams.com') ||
    domain.includes('csc.bonhams.com') ||
    domain.includes('preprod-csc01-en.bonhams.com') ||
    domain.includes('preprod-csc01.bonhams.com')
  ) {
    return Brand.cornette
  }

  return Brand.bonhams
}
