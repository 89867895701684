import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { FireButton } from '@nx/fire/button'
import { Body } from '@nx/fire/typography'
import { getTranslate } from '@nx/translations'
import { GTM_EVENTS, useDataLayer } from '@nx/utils/google-tag-manager'

import {
  SelectedCurrencyLabel,
  StyledActions,
  StyledBody,
  StyledCloseButton,
  StyledCurrencies,
  StyledCurrencyRow,
  StyledFireFormSearchInput,
  StyledFireModal,
  StyledFlag,
  StyledFlagList,
  StyledFlagRow,
  StyledFlagSymbols,
  StyledFlags,
  StyledHeaderContainer,
  StyledSeparator,
} from './FireCurrencyModal.styles'
import translate from './FireCurrencyModal.translate.json'
import { CurrencyConfig, CurrencyRates } from './types'
import { useGetFlagCodes } from './useGetFlagCodes'

const { text } = getTranslate(translate)

export function FireCurrencyModal({
  isOpen,
  setIsOpen,
  currencyRates,
  currencyConfig,
  setCurrencyConfig,
  notDefinedCookieValue,
  assetsUrl,
}: CurrencyModalProps) {
  const dataLayer = useDataLayer()
  const flagsRef = useRef<HTMLOListElement>(null)
  const flagCodes = useGetFlagCodes(currencyRates)
  const [searchTerm, setSearchTerm] = useState<string>()
  const selectedCurrencyRef = useRef<HTMLDivElement>(null)

  const [selectedCurrencyISO, setSelectedCurrencyISO] = useState(
    currencyConfig?.userSelectedCurrency
  )

  const [selectedCurrencyFlag, setSelectedCurrencyFlag] = useState<string>()

  useEffect(() => {
    setSelectedCurrencyISO(currencyConfig?.userSelectedCurrency)
  }, [currencyConfig?.userSelectedCurrency])

  useEffect(() => {
    const flagIndex = flagCodes.findIndex(
      (flagCode) =>
        flagCode.currencyCode === currencyConfig?.userSelectedCurrency
    )
    setSelectedCurrencyFlag(
      flagIndex >= 0 ? flagCodes[flagIndex].flagCode : undefined
    )
  }, [currencyConfig, flagCodes])

  const trackCurrencyChange = () => {
    const newSelectedCurrency =
      selectedCurrencyISO !== notDefinedCookieValue
        ? selectedCurrencyISO
        : notDefinedCookieValue

    dataLayer.push({
      event: GTM_EVENTS.currencySelection,
      from: currencyConfig?.userSelectedCurrency,
      to: newSelectedCurrency,
    })

    dataLayer.push({
      selected_currency: newSelectedCurrency,
    })
  }

  return (
    <StyledFireModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      allowTouchMove={(el) =>
        !!flagsRef.current && flagsRef.current.contains(el)
      }
      heading={text('currencyModal.title')}
      onAfterClose={() => {
        if (
          currencyConfig &&
          setCurrencyConfig &&
          selectedCurrencyISO &&
          selectedCurrencyISO !== currencyConfig.userSelectedCurrency
        ) {
          trackCurrencyChange()

          setCurrencyConfig({
            ...currencyConfig,
            userSelectedCurrency: selectedCurrencyISO,
          })
        }

        setSearchTerm(undefined)
      }}
    >
      <StyledFlagList>
        <div>
          <Body>{text('currencyModal.subHeading')}</Body>
          <StyledHeaderContainer>
            {selectedCurrencyFlag && currencyRates && selectedCurrencyISO && (
              <div tabIndex={-1} ref={selectedCurrencyRef}>
                <SelectedCurrencyLabel $level="S">
                  {text('currencyModal.selectedCurrency')}
                </SelectedCurrencyLabel>
                <StyledFlagSymbols>
                  <StyledFlag
                    width={32}
                    height={32}
                    src={`${
                      assetsUrl || ''
                    }/web-assets/flags/${selectedCurrencyFlag}.png`}
                    alt={text('currencyModal.flagFor', {
                      currency: selectedCurrencyISO,
                    })}
                  ></StyledFlag>
                  <StyledCurrencies>
                    {selectedCurrencyISO}
                    {selectedCurrencyISO !== notDefinedCookieValue &&
                    currencyRates.rates[selectedCurrencyISO].bonhams_code !==
                      selectedCurrencyISO
                      ? ` - ${currencyRates.rates[selectedCurrencyISO].bonhams_code}`
                      : null}
                  </StyledCurrencies>
                </StyledFlagSymbols>
              </div>
            )}
            <StyledFireFormSearchInput
              label={text('currencyModal.searchLabel')}
              onSubmit={(e) => e.preventDefault()}
              submitButton={{
                text: text('currencyModal.searchButton'),
              }}
              input={{
                onChange: (e) => setSearchTerm(e.target.value.toLowerCase()),
                type: 'search',
                placeholder: text('currencyModal.searchPlaceholder'),
              }}
            />
          </StyledHeaderContainer>
          <StyledSeparator />
        </div>
        <StyledFlags
          as="ol"
          aria-label={text('currencyModal.currencies')}
          ref={flagsRef}
        >
          {currencyRates &&
            flagCodes
              .filter(
                ({ currencyCode }) =>
                  !searchTerm ||
                  currencyRates.rates[currencyCode].iso_currency_name
                    .toLowerCase()
                    .includes(searchTerm) ||
                  currencyCode.toLowerCase().includes(searchTerm)
              )
              .map(({ currencyCode, flagCode }, index) => {
                // istanbul ignore next
                if (!currencyRates.rates[currencyCode]) {
                  return null
                }

                return (
                  <StyledCurrencyRow key={index}>
                    <StyledFlagRow
                      onClick={() => {
                        setSelectedCurrencyISO(currencyCode)
                        setSelectedCurrencyFlag(flagCode)
                        selectedCurrencyRef.current?.focus()
                      }}
                    >
                      <StyledFlagSymbols>
                        <StyledFlag
                          src={`${
                            assetsUrl || ''
                          }/web-assets/flags/${flagCode}.png`}
                          width={32}
                          height={32}
                          alt={text('currencyModal.flagFor', {
                            currency: currencyCode,
                          })}
                        />
                        <StyledCurrencies as="span">
                          {currencyCode}
                          {currencyRates.rates[currencyCode].bonhams_code !==
                          currencyCode
                            ? ` - ${currencyRates.rates[currencyCode].bonhams_code}`
                            : null}
                        </StyledCurrencies>
                      </StyledFlagSymbols>
                      <StyledBody as="span">
                        {currencyRates.rates[currencyCode].iso_currency_name}
                      </StyledBody>
                    </StyledFlagRow>
                  </StyledCurrencyRow>
                )
              })}
        </StyledFlags>
        <StyledActions>
          {selectedCurrencyISO &&
            selectedCurrencyISO !== notDefinedCookieValue && (
              <FireButton
                variant="secondary"
                onClick={() => {
                  setSelectedCurrencyISO(notDefinedCookieValue)
                  setSelectedCurrencyFlag(undefined)
                }}
              >
                {text('currencyModal.clearSelected')}
              </FireButton>
            )}
          <StyledCloseButton
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {text('currencyModal.close')}
          </StyledCloseButton>
        </StyledActions>
      </StyledFlagList>
    </StyledFireModal>
  )
}

interface CurrencyModalProps {
  notDefinedCookieValue: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  currencyRates: CurrencyRates | undefined
  currencyConfig: CurrencyConfig | undefined
  setCurrencyConfig:
    | Dispatch<SetStateAction<CurrencyConfig | undefined>>
    | undefined
  assetsUrl?: string
}
