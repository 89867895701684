import { Brand, EBrand } from '@bonhams1793/bonhams-typescript'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { CollectionPage, Event, Place, Product } from 'schema-dts'

import {
  ActiveLanguages,
  LocaleURLFolder,
  SmartlingAttributes,
  SmartlingClassNames,
  stripLocaleFromPath,
} from '@nx/smartling'

import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'

const { publicRuntimeConfig } = getConfig()

const BONHAMS_TITLE: Record<Brand, string> = {
  [Brand.bonhams]: 'Bonhams',
  [Brand.skinner]: 'Bonhams Skinner',
  [Brand.cornette]: 'Bonhams Cornette de Saint Cyr',
  [Brand.bukowskis]: 'Bonhams',
  [Brand.bruunRasmussen]: 'Bonhams',
  [Brand.cars]: 'Bonhams Cars',
  [Brand.carsOnline]: 'Bonhams Cars Online',
}

const BONHAMS_BASE_URL: Record<Brand, string> = {
  [Brand.bonhams]: publicRuntimeConfig.WEB_BASE_URL,
  [Brand.skinner]: publicRuntimeConfig.SKINNER_BASE_URL,
  [Brand.cornette]: publicRuntimeConfig.CORNETTE_BASE_URL,
  [Brand.bukowskis]: publicRuntimeConfig.WEB_BASE_URL,
  [Brand.bruunRasmussen]: publicRuntimeConfig.WEB_BASE_URL,
  [Brand.cars]: publicRuntimeConfig.CARS_BASE_URL,
  [Brand.carsOnline]: publicRuntimeConfig.BONHAMS_CARS_ONLINE_BASE_URL,
}

export function MetaData({
  name,
  description,
  isNoRobotsTagShown,
  brand = Brand.bonhams,
  imageUrl,
  ldJson,
  shouldBeTranslated = true,
  baseUrl,
}: MetaDataProps) {
  const router = useRouter()

  const brandTitle = BONHAMS_TITLE[brand] || 'Bonhams'
  const localesExp = new RegExp(
    `/(${Object.values(ActiveLanguages).join('|')})`,
    'i'
  )
  const finalBaseUrl = baseUrl.replace(localesExp, '')
  const cleanUrl = router.asPath.split('?')[0].split('#')[0]
  const currentPath = stripLocaleFromPath(cleanUrl)

  const title = name ? `${brandTitle} : ${name}` : brandTitle
  const ogImage = imageUrl
    ? imageUrl
    : populateUrl('/web-assets/bonhams-logo.png')
  const translationAttribute = shouldBeTranslated
    ? SmartlingAttributes.translate
    : SmartlingAttributes.noTranslate

  const alternateUrls: Record<string, string> = {
    en: `${finalBaseUrl}${currentPath}`,
    'fr-FR': `${finalBaseUrl}/${LocaleURLFolder.french}${currentPath}`,
    'zh-CN': `${finalBaseUrl}/${LocaleURLFolder.chineseSimplified}${currentPath}`,
    'zh-HK': `${finalBaseUrl}/${LocaleURLFolder.chineseTraditional}${currentPath}`,
    'nl-BE': `${finalBaseUrl}/${LocaleURLFolder.flemish}${currentPath}`,
  }

  if (baseUrl === BONHAMS_BASE_URL.cornette) {
    alternateUrls.en = `${finalBaseUrl}/${LocaleURLFolder.english}${currentPath}`
    alternateUrls['fr-FR'] = `${finalBaseUrl}${currentPath}`
  }

  return (
    <Head>
      <title key="title" {...translationAttribute}>
        {title}
      </title>

      <meta
        key="meta-title"
        name="title"
        content={title}
        {...translationAttribute}
      />
      {description && (
        <meta
          key="meta-description"
          name="description"
          content={description}
          {...translationAttribute}
        />
      )}
      <meta
        key="meta-keywords"
        name="keywords"
        content="art auction, antiquarian, antique, antiques, antiquities, valuation, arms, armour, armour, art, Art Deco, asian art, auction, auction house, auctions, auctioneers, autographics, automobilia, Bonhams, Bonhams &amp; Butterfields, Bonhams &amp; Goodmans, books, Brooks, buying art, Cartier, ceramics, classic, coins, collectable, collectibles, contemporary, crystal, cubism, drawing, drawings, engraving, etching, fine art, first editions, entertainment, fishing, frames, furniture, Gallé, glass, Glenginings, Goodmans, Impressionist, Islamic art, jewellery, jewellery, maps, manuscripts, medal, memorabilia, models, motorbike, motorcar, motorcycle, musical instruments, online auction, online auctions, Oriental carpets, Oriental rugs, painting, paintings, Persian carpets, Persian rugs, piano, photographs, pop, porcelain, portrait miniatures, prints, probate, rare, rare books, Rococo, scientific instrument, sculpture, silver, stamps, textiles, tribal art, topographic, toys, valuation, vase, Warhol, watch, watches, watercolours, works of art, London auction house, fine art, art and antique, art &amp; antiques, fine, arts, America, USA, UK"
      />

      <meta
        key="og-title"
        property="og:title"
        content={title}
        {...translationAttribute}
      />
      <meta key="og-image" property="og:image" content={ogImage} />
      {description && (
        <meta
          property="og:description"
          key="og-description"
          content={description}
          {...translationAttribute}
        />
      )}
      <meta
        key="og-url"
        property="og:url"
        content={`${finalBaseUrl}${router.asPath}`}
      />
      <meta key="og-type" property="og:type" content="website" />
      <meta
        key="apple-itunes-app"
        name="apple-itunes-app"
        content="app-id=404549285"
      />

      {isNoRobotsTagShown && <meta name="robots" content="noindex, nofollow" />}

      {ldJson && (
        <script
          key="ld-json"
          type="application/ld+json"
          {...translationAttribute}
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              ...ldJson,
            }),
          }}
        />
      )}

      <link
        key="canonical"
        rel="canonical"
        href={`${finalBaseUrl}${currentPath}`}
        className={SmartlingClassNames.preventLinkRewrite}
      />

      {Object.keys(alternateUrls).map((locale) => (
        <link
          key={`alternate-${locale}`}
          rel="alternate"
          hrefLang={locale}
          href={alternateUrls[locale]}
          className={SmartlingClassNames.preventLinkRewrite}
        />
      ))}
    </Head>
  )
}

export interface MetaDataProps {
  name?: string
  description?: string
  imageUrl?: string
  isNoRobotsTagShown?: boolean
  brand?: EBrand
  ldJson?: Event | Product | CollectionPage | Omit<Place, 'string'>
  shouldBeTranslated?: boolean
  baseUrl: string
}
