export const escapeForRegex = (text: string) => {
  const specials = [
    '/',
    '.',
    '*',
    '+',
    '?',
    '|',
    '(',
    ')',
    '[',
    ']',
    '{',
    '}',
    '\\',
  ]

  return text.replace(
    new RegExp('(\\' + specials.join('|\\') + ')', 'g'),
    '\\$1'
  )
}
