import {
  BodyScrollOptions,
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import ReactModal from 'react-modal'

import { CloseIcon } from '@nx/fire/assets'

import {
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledHeader,
  StyledHeading,
  StyledModal,
  StyledOverlay,
  StyledReactModalBody,
} from './fire-modal.styles'

ReactModal.setAppElement('body')

export const FireModal = ({
  children,
  header,
  heading,
  setIsOpen,
  allowTouchMove,
  className,
  isOpen,
  hideCloseButton,
  ...props
}: FireModalProps) => {
  const modalRef = useRef<HTMLDivElement>()

  const closeModal = useCallback(() => {
    /* istanbul ignore next */
    if (!modalRef.current) return

    setIsOpen(false)

    enableBodyScroll(modalRef.current)
  }, [setIsOpen])

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      closeModal()
    }
  }, [isOpen, closeModal])

  return (
    <>
      <StyledReactModalBody />
      <ReactModal
        {...props}
        isOpen={isOpen}
        contentRef={(el) => {
          modalRef.current = el
        }}
        onAfterOpen={() => {
          modalRef.current &&
            disableBodyScroll(modalRef.current, {
              allowTouchMove,
            })
        }}
        onRequestClose={closeModal}
        /* Note that the className properties are required to prevent react-modal
         from overriding the styles defined in contentElement and overlayElement */
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <StyledModal
            {...props}
            className={`${props.className}${className ? ` ${className}` : ''}`}
          >
            <StyledHeader>
              {/* Get around concerns of having multiple H1s in the DOM
            https://github.com/KittyGiraudel/react-a11y-dialog/blob/main/index.js#L51
            */}
              {header
                ? header
                : heading && (
                    <StyledHeading
                      as="p"
                      $level={4}
                      aria-level={1}
                      role="heading"
                    >
                      {heading}
                    </StyledHeading>
                  )}
              {!hideCloseButton && (
                <StyledCloseButtonContainer>
                  <StyledCloseButton onClick={closeModal}>
                    <CloseIcon width="20" height="20" title="Close modal" />
                  </StyledCloseButton>
                </StyledCloseButtonContainer>
              )}
            </StyledHeader>
            {children}
          </StyledModal>
        )}
        overlayElement={(props, contentElement) => (
          <StyledOverlay {...props} data-testid="modal-overlay">
            {contentElement}
          </StyledOverlay>
        )}
      >
        {children}
      </ReactModal>
    </>
  )
}

export interface FireModalProps extends ReactModal.Props {
  children: ReactNode
  header?: ReactNode
  heading?: string
  allowTouchMove?: BodyScrollOptions['allowTouchMove']
  setIsOpen: (value: boolean) => void
  hideCloseButton?: boolean
}
