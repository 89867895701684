// sort-imports-ignore
import { GlobalReset } from './reset'
import { GlobalTypography } from './typography'
import { GlobalStyle } from './generic'

export { GlobalFonts } from './fonts'

export function FireGlobalStyles() {
  return (
    <>
      <GlobalReset />
      <GlobalStyle />
      <GlobalTypography />
    </>
  )
}
