import { Brand, EBrand } from '@bonhams1793/bonhams-typescript'
import getConfig from 'next/config'
import { ForwardedRef, forwardRef } from 'react'
import { ThemeProvider } from 'styled-components'

import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  XLogoIcon,
  YouTubeIcon,
} from '@nx/fire/assets'
import { FireFooter } from '@nx/fire/footer'
import { getTranslate } from '@nx/translations'

import { getTheme } from '@web/helpers/utils'

import translate from './Footer.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export function FooterRef(
  { brand }: FooterProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const theme = getTheme(brand)

  const links = [
    {
      text: text('privacyPolicy'),
      href: `${publicRuntimeConfig.WEB_BASE_URL}/legals/9945/`,
      forceAnchor: true,
    },
    {
      text: text('contacts'),
      href: '/people/',
      forceAnchor: true,
    },
    {
      text: text('careers'),
      href: '/careers/',
      forceAnchor: true,
    },
    {
      text: text('howToBuy'),
      href: `${publicRuntimeConfig.WEB_BASE_URL}/${
        brand === 'skinner' ? 'stories/34976/' : 'how-to-buy/'
      }`,
      forceAnchor: true,
    },
    {
      text: text('help'),
      href: '/video/30262/#/MR3_main_index_key=sale&m3=3',
      forceAnchor: true,
    },
    {
      text: text('termsConditions'),
      href: '/legals',
      forceAnchor: true,
    },
    {
      text: text('modernSlaveryStatement'),
      href: `${publicRuntimeConfig.WEB_BASE_URL}/legals/26220/`,
      forceAnchor: true,
    },
    {
      text: text('aboutUs'),
      href: [Brand.cornette, Brand.skinner].includes(brand)
        ? '/about-us'
        : '/about_us',
      forceAnchor: ![Brand.cornette, Brand.skinner].includes(brand),
    },
    {
      text: text('press'),
      href: '/press_contacts/',
      forceAnchor: true,
    },
    {
      text: text('buyCatalogues'),
      href: 'https://catalogues.bonhams.com/',
      forceAnchor: true,
    },
  ]

  const socialLinks = [
    {
      icon: <InstagramIcon title={`${text('logo', { name: 'Instagram' })} `} />,
      href: 'https://www.instagram.com/bonhams1793/',
    },
    {
      icon: <FacebookIcon title={`${text('logo', { name: 'Facebook' })} `} />,
      href: 'https://www.facebook.com/bonhams1793',
    },
    {
      icon: <XLogoIcon title={`${text('logo', { name: 'X' })} `} />,
      href: 'https://x.com/bonhams1793',
    },
    {
      icon: <PinterestIcon title={`${text('logo', { name: 'Pinterest' })} `} />,
      href: 'https://www.pinterest.co.uk/Bonhams1793/',
    },
    {
      icon: <YouTubeIcon title={`${text('logo', { name: 'YouTube' })} `} />,
      href: 'https://www.youtube.com/channel/UCVgDPGHmH1EbTcKz0KsOD8g',
    },
  ]

  const logoTitle =
    theme.name === Brand.bonhams
      ? 'Bonhams'
      : theme.name === Brand.skinner
        ? 'Bonhams Skinner'
        : 'Bonhams Cornette de Saint Cyr'

  return (
    <ThemeProvider theme={theme}>
      <FireFooter
        ref={ref}
        logoTitle={logoTitle}
        links={links}
        socialLinks={socialLinks}
        oneTrust={{
          enabled: true,
          text: text('cookies'),
        }}
        weChatEnabled={true}
        formData={{
          apiBaseUrl: publicRuntimeConfig.WEB_API_URL,
        }}
      />
    </ThemeProvider>
  )
}

interface FooterProps {
  brand: EBrand
}

export const Footer = forwardRef(FooterRef)
