import { EBrand } from '@bonhams1793/bonhams-typescript'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import { ELocaleURLFolder, LocaleURLFolder } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import {
  Container,
  Inner,
  StyledBody,
  StyledButton,
} from './TranslationsReturnHomeBanner.styles'
import translate from './TranslationsReturnHomeBanner.translate.json'

const { text } = getTranslate(translate)

export function TranslationsReturnHomeBanner({ brand }: { brand: EBrand }) {
  const router = useRouter()
  const [visible, setVisible] = useState(false)
  const lang = useRef<ELocaleURLFolder>()

  const baseLangauge =
    brand === 'cornette' ? LocaleURLFolder.french : LocaleURLFolder.english

  useEffect(() => {
    lang.current = window.location.href.split('/')[3] as ELocaleURLFolder

    if (
      lang.current !== 'default' &&
      router.locales &&
      router.locales?.includes(lang.current) &&
      document.documentElement.lang === baseLangauge
    ) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [router, baseLangauge])

  return visible && lang.current && lang.current !== 'default' ? (
    <Container>
      <Inner>
        <StyledBody $level="S">{text(`body-${lang.current}`)}</StyledBody>
        <StyledButton href={`/${lang.current}/`} size="S">
          {text(`button-${lang.current}`)}
        </StyledButton>
      </Inner>
    </Container>
  ) : null
}
