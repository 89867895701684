import { Id, Slide, ToastOptions, toast } from 'react-toastify'

import { CloseIcon } from '@nx/fire/assets'

import {
  StyledBody,
  StyledCloseButton,
  StyledErrorIcon,
  StyledInfoIcon,
  StyledSuccessIcon,
  StyledToastContainer,
  StyledWarningIcon,
} from './fire-toast.styles'

export const ToastSetup = () => (
  <StyledToastContainer
    position="top-right"
    transition={Slide}
    closeOnClick={false}
    autoClose={4000}
    closeButton={({ closeToast }) => (
      <StyledCloseButton onClick={closeToast}>
        <CloseIcon title="Close" />
      </StyledCloseButton>
    )}
    role="status"
  />
)

export const showToast = {
  success: (message: React.ReactNode, options?: ToastOptions) =>
    toast.success(() => <StyledBody>{message}</StyledBody>, {
      icon: () => <StyledSuccessIcon title="success icon" />,
      ...options,
    }),
  info: (message: React.ReactNode, options?: ToastOptions) =>
    toast.info(() => <StyledBody>{message}</StyledBody>, {
      icon: () => <StyledInfoIcon title="info icon" />,
      ...options,
    }),
  warning: (message: React.ReactNode, options?: ToastOptions) =>
    toast.warning(() => <StyledBody>{message}</StyledBody>, {
      icon: () => <StyledWarningIcon title="warning icon" />,
      ...options,
    }),
  error: (message: React.ReactNode, options?: ToastOptions) =>
    toast.error(() => <StyledBody>{message}</StyledBody>, {
      icon: () => <StyledErrorIcon title="error icon" />,
      ...options,
    }),
  dismiss: (id: Id | null) => id && toast.dismiss(id),
}
