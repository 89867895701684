import { QueryClient } from '@tanstack/react-query'
import { NextRouter } from 'next/router'

import { axiosInstance } from '@nx/fetch'
import { removeAuthCookies } from '@nx/user-auth-cookies'

export const logout = async ({
  baseURL,
  queryClient,
  router,
  onSuccess,
}: LogoutParams) => {
  axiosInstance
    .get('/api/auth/logout/', {
      baseURL,
    })
    .then(async () => {
      removeAuthCookies()

      await Promise.allSettled(
        [
          'user',
          'followed-lots',
          'followed-departments',
          'followed-sales',
          'followed-artists',
          'interests',
          'userAddresses',
          'userRegistrations',
        ].map((key) =>
          queryClient.resetQueries({ queryKey: [key], exact: false })
        )
      )

      if (router.pathname.includes('mybonhams')) {
        onSuccess?.()
        await router.push('/')
      }
    })
    .catch(async () => {
      await router.push('/logout/')
    })
}

interface LogoutParams {
  baseURL: string
  queryClient: QueryClient
  router: NextRouter
  onSuccess?: () => void
}
