import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import { SearchIcon } from '@nx/fire/assets'
import { SkipToContent } from '@nx/fire/skip-to-content'
import VisuallyHidden from '@nx/fire/visually-hidden'
import { getTranslate } from '@nx/translations'

import {
  CentrePositioner,
  FireStyledDesktopAccountNav,
  FireStyledLinkNavItem,
  FireStyledMenuButton,
  FireStyledTopGrid,
  LanguageNavHover,
  LinksWrapper,
  LogoWrapper,
  MainNav,
  SearchButton,
  StyledNavButton,
  Wrapper,
} from './FireHeader.styles'
import translate from './FireHeader.translate.json'
import { AccountNav } from './components/AccountNav'
import { LanguageNav } from './components/LanguageNav'
import { SearchForm } from './components/SearchForm'

const { text } = getTranslate(translate)

export function FireHeader({
  navLinks,
  searchLink,
  authLinks,
  languageLinks,
  currencyLink,
  logos,
  ...props
}: FireHeaderProps) {
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [showDesktopLanguageNav, setShowDesktopLanguageNav] =
    useState<boolean>(false)
  const [showMobileLanguageNav, setShowMobileLanguageNav] =
    useState<boolean>(false)

  const mainNavRef = useRef(null)
  const router = useRouter()

  function openMobileNav() {
    mainNavRef.current && disableBodyScroll(mainNavRef.current)
    setShowMobileNav(true)

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
      window.addEventListener('resize', closeMobileNav, { once: true })
    }
  }

  const closeMobileNav = useCallback(() => {
    clearAllBodyScrollLocks()
    setShowMobileNav(false)
    setShowMobileLanguageNav(false)

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', closeMobileNav)
    }
  }, [])

  useEffect(() => {
    function handleRouteChange() {
      showMobileNav && closeMobileNav()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [showMobileNav, closeMobileNav, router.events])

  const isMultilingual = languageLinks && languageLinks.length > 0

  return (
    <Wrapper {...props}>
      <SkipToContent />
      <FireStyledTopGrid>
        <CentrePositioner>
          <FireStyledMenuButton
            variant="ghost"
            onClick={() => (showMobileNav ? closeMobileNav() : openMobileNav())}
            $isOpen={showMobileNav}
          >
            <VisuallyHidden>
              {showMobileNav ? text('closeNav') : text('openNav')}
            </VisuallyHidden>
          </FireStyledMenuButton>

          <LogoWrapper>{logos}</LogoWrapper>

          <SearchButton href={searchLink}>
            <SearchIcon title={text('search')} />
          </SearchButton>
          <FireStyledDesktopAccountNav aria-label={text('accountNavLabel')}>
            <AccountNav closeMobileNav={closeMobileNav} links={authLinks} />
            {isMultilingual && (
              <LanguageNavHover>
                <LanguageNav
                  links={languageLinks}
                  show={showDesktopLanguageNav}
                  toggleVisible={setShowDesktopLanguageNav}
                  uniqueName="language-sub-menu" /* uniqueName is needed for Smartling and must not be changed */
                />
              </LanguageNavHover>
            )}
            {currencyLink && (
              <StyledNavButton
                as="button"
                onClick={(e: React.SyntheticEvent) => {
                  currencyLink.onClick?.(e)
                  currencyLink.shouldCloseMobileNav && closeMobileNav()
                }}
                data-cy="currency-modal-button"
              >
                {currencyLink.text}
              </StyledNavButton>
            )}
          </FireStyledDesktopAccountNav>
        </CentrePositioner>
      </FireStyledTopGrid>
      <MainNav
        ref={mainNavRef}
        $show={showMobileNav}
        aria-label={text('mainNavLabel')}
        data-testid="main-nav"
      >
        <CentrePositioner>
          <LinksWrapper>
            {navLinks.map(({ href, text, isActive }, index) => (
              <FireStyledLinkNavItem
                href={href}
                $isActive={isActive}
                key={index}
              >
                {text}
              </FireStyledLinkNavItem>
            ))}
            <AccountNav
              closeMobileNav={closeMobileNav}
              $isMobileOnly
              links={authLinks}
            />
            {isMultilingual && (
              <LanguageNav
                links={languageLinks}
                show={showMobileLanguageNav}
                toggleVisible={setShowMobileLanguageNav}
                uniqueName="language-mobile-sub-menu" /* uniqueName is needed for Smartling and must not be changed */
                $isMobileOnly
              />
            )}
            {currencyLink && (
              <StyledNavButton
                variant="ghost"
                $isMobileOnly
                onClick={(e: React.SyntheticEvent) => {
                  currencyLink.onClick?.(e)
                  currencyLink.shouldCloseMobileNav && closeMobileNav()
                }}
              >
                {currencyLink.text}
              </StyledNavButton>
            )}
          </LinksWrapper>

          <SearchForm link={searchLink} />
        </CentrePositioner>
      </MainNav>
    </Wrapper>
  )
}

interface HeaderLinkProps {
  text: React.ReactNode
  href: string
  forceAnchor?: boolean
  onClick?: (e: React.SyntheticEvent) => void
  shouldCloseMobileNav?: boolean
}

type NavLinkProps = HeaderLinkProps & { isActive?: boolean }

export interface FireHeaderProps {
  navLinks: NavLinkProps[]
  searchLink: string
  authLinks: HeaderLinkProps[]
  languageLinks?: {
    text: string
    href: string
  }[]
  currencyLink?: Omit<HeaderLinkProps, 'href' | 'forceAnchor'>
  logos: React.ReactNode[]
}
