import getConfig from 'next/config'

import { escapeForRegex } from '@nx/helpers'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const skinnerBaseUrl = publicRuntimeConfig.SKINNER_BASE_URL

const { hostname } = new URL(skinnerBaseUrl)

//the paths within this array will be rendered with Skinner theme
const skinnerAllowedPaths = [
  '/about-us/',
  '/sell/',
  '/legals/',
  '/404/',
  '/logout/',
  '/robots.txt',
]

const allowedPaths = new RegExp(
  `(^(?!(^\\/$|${skinnerAllowedPaths.map((path) => escapeForRegex(path)).join('|')})).*$)`
)

export const skinnerSubDomainRedirects: RedirectConfig[] = [
  {
    source: allowedPaths,
    hostname,
    destination: `${baseUrl}{{0}}`,
    permanent: true,
  },
]
