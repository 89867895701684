import axios from 'axios'
import Cookies from 'js-cookie'

import { BonhamsCookies } from '@nx/global-types'
import { isServer } from '@nx/helpers'

import {
  tokenRejectRequestInterceptor,
  tokenRejectResponseInterceptor,
} from './interceptors/token.interceptor'

interface GetAxiosInstanceProps {
  accessToken?: string
  baseURL: string
  timeout?: number
}

export function getAxiosInstance({
  accessToken,
  baseURL,
  timeout,
}: GetAxiosInstanceProps) {
  return axios.create({
    timeout,
    baseURL,
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
  })
}

interface CreateAxiosClientProps {
  refreshTokenSSR?: string
  accessTokenSSR?: string
  baseURL: string
  timeout?: number
  isWithInterceptor?: boolean
}

export function createAuthenticatedAxiosClient({
  refreshTokenSSR,
  accessTokenSSR,
  baseURL,
  timeout = 5000,
  isWithInterceptor = true,
}: CreateAxiosClientProps) {
  const cookies: BonhamsCookies = Cookies.get()

  const accessToken = isServer() ? accessTokenSSR : cookies.bonhams_access
  const refreshToken = isServer() ? refreshTokenSSR : cookies.bonhams_token

  const authenticatedAxiosClient = getAxiosInstance({
    accessToken,
    baseURL,
    timeout,
  })

  if (isWithInterceptor) {
    authenticatedAxiosClient.interceptors.request.use(
      tokenRejectRequestInterceptor({ accessToken }),
      undefined
    )
    authenticatedAxiosClient.interceptors.response.use(
      undefined,
      tokenRejectResponseInterceptor({
        authenticatedAxiosClient: getAxiosInstance({
          accessToken,
          baseURL,
          timeout,
        }),
        refreshToken,
      })
    )
  }

  return authenticatedAxiosClient
}
